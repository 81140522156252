import { render, staticRenderFns } from "./Recruit2.vue?vue&type=template&id=7a2ceae4&scoped=true&"
import script from "./Recruit2.vue?vue&type=script&lang=js&"
export * from "./Recruit2.vue?vue&type=script&lang=js&"
import style0 from "./Recruit2.vue?vue&type=style&index=0&id=7a2ceae4&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a2ceae4",
  null
  
)

export default component.exports