<template>
  <Recruit1 v-if="widthScreen"></Recruit1>
  <Recruit2 v-else></Recruit2>
</template>

<script>
import Recruit1 from './Recruit1.vue'
import Recruit2 from './Recruit2.vue'

export default {
  components: { Recruit1, Recruit2 },
  data() {
    return {
      widthScreen: false,
    }
  },
  created() {
    document.title = '九章大模型(MathGPT)'
    if (window.screen.width > 1920) this.widthScreen = true
  },
}
</script>

<style lang="less" scoped></style>
